<template>
  <div class="section">
    <cust-header
      class="header"
      :data="busoppoInfoDetail"
    />
    <tab-composite
      ref="tabComposite"
      class="main"
      fixed-height="168"
      :tab-list="tabList"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      :need-btn="needBtn"
      :need-border="false"
      @getIndex="getIndex"
    >
      <ul
        slot="1"
        class="list-wrapper"
      >
        <follow-info ref="followInfo" />
      </ul>
      <ul
        slot="2"
        class="list-wrapper"
      >
        <cust-info ref="custInfo" />
      </ul>
      <ul
        slot="3"
        class="list-wrapper"
      >
        <contacts-info ref="contactsInfo" />
      </ul>
    </tab-composite>
    <div class="bottom-postion">
      <cube-button
        v-if="index === 0 && btnAid.includes('edit')"
        class="btn"
        @click="toFollow"
      >
        编辑跟进
      </cube-button>
    </div>
  </div>
</template>

<script >
import { mapGetters } from 'vuex';

import { followDetail } from '_api/follow';
import custHeader from '_c/cust-header';
import tabComposite from '_c/tab-composite';

import { findValueByKey } from '@/lib/until';
import btnAuth from '@/mixins/btnAuth';
import contactsInfo from '@/views/contacts/components/contacts-info';
import custInfo from '@/views/cust/components/cust-info';
import followInfo from '@/views/follow/components/follow-info';

export default {
  name: 'FollowDetails',
  components: {
    custHeader,
    tabComposite,
    followInfo,
    contactsInfo,
    custInfo
  },
  mixins: [btnAuth],
  data() {
    return {
      busoppoInfoDetail: {},
      oppoTrackDetail: {},
      custInfoDetail: {},
      custContactsDetail: {},
      tabList: [{
        label: '跟进信息',
        value: 0
      }, {
        label: '客户信息',
        value: 1
      }, {
        label: '联系人',
        value: 2
      }],
      func: followDetail,
      index: 0,
      loadEnable: false
    };
  },
  computed: {
    ...mapGetters('follow', ['aidList']),
    ...mapGetters(['dictList']),
    needBtn() {
      if (this.index === 0 && this.btnAid.includes('edit')) {
        return true;
      } else {
        return false;
      }
    }
  },
  // created() {
  //   this.getAuthBtn(this.$route.params.type);
  // },
  mounted() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      followDetail(this.$route.params.followId).then((res) => {
        if (res.flag) {
          // 头部信息
          const busoppoInfoDetail = res.data.busoppoInfoDetail;
          this.busoppoInfoDetail = {
            title: busoppoInfoDetail.custCn,
            status: findValueByKey(this.dictList.oppoStatus, busoppoInfoDetail.oppoStatus),
            mainList: [
              `商机产品：${busoppoInfoDetail.comboName}`,
              `跟进类型：${findValueByKey(this.dictList.trackType, res.data.oppoTrackDetail.trackType)}`,
              `进程推进：${findValueByKey(this.dictList.reliable, busoppoInfoDetail.trackReliable)}`
            ]
          };
          // 跟进信息
          this.oppoTrackDetail = { ...res.data.oppoTrackDetail, busTypeId: res.data.busoppoInfoDetail.busTypeIds };
          this.$refs.followInfo.getData(this.oppoTrackDetail);
          // 客户信息
          this.custInfoDetail = res.data.custInfoDetail;
          this.$refs.custInfo.getData(this.custInfoDetail);
          // 联系人信息
          this.custContactsDetail = res.data.custContactsDetail;
          this.custContactsDetail.sex = findValueByKey(this.dictList.sex, this.custContactsDetail.sex);
          this.$refs.contactsInfo.getData(this.custContactsDetail);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getIndex(index) {
      this.index = index;
    },
    toFollow() {
      this.$router.push(
        {
          name: 'AddeditFollow',
          query: {
            followId: this.oppoTrackDetail.id
          }
        }
      );
    }
  }
};
</script>
<style  lang="scss"  scoped  src="@/assets/css/components/btn-bottom.scss"></style>
<style  lang="scss"  scoped>
.header{
  width:100%;
  height:168px;
}
</style>
