<template>
  <div class="cust-box">
    <div class="cust-content">
      <div class="cust-title">
        基本信息
      </div>
      <view-match :list="basicList" />
    </div>
    <div class="cust-content">
      <div class="cust-title">
        工商信息
      </div>
      <view-match :list="icList" />
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays } from '@/lib/until.js';
import {mapGetters} from 'vuex';
import {findValueByKey} from '../../../lib/until';

export default {
  components: {
    viewMatch
  },
  data() {
    return {
      basicData: {
        custCn: {
          title: '客户名称',
          value: '杭州今元标矩科技有限公司'
        },
        address: {
          title: '客户地址',
          value: ''
        },
        scale: {
          title: '公司规模',
          value: ''
        },
        generalTaxpayerFlag: {
          title: '是否一般纳税人',
          value: ''
        },
        license: {
          type: 'image',
          title: '营业执照',
          image: []
          // image: '../../assets/img/logo.png'
        }
      },
      icData: {
        custCreditCode: {
          title: '企业信用代码',
          value: ''
        },
        busStatus: {
          title: '企业经营状态',
          value: ''
        },
        legalPerson: {
          title: '法人代表',
          value: ''
        },
        establishDate: {
          title: '成立时间',
          value: ''
        },
        custInd: {
          title: '行业类型',
          value: ''
        },
        regAddr: {
          title: '注册地址',
          value: ''
        },
        createName: {
          title: '创建人',
          value: ''
        },
        createTime: {
          title: '创建时间',
          value: ''
        }
      }
    };
  },
  computed: {
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.basicData);
    },
    icList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.icData);
    },
    ...mapGetters(['dictList'])
  },
  methods: {
    getData(data) {
      // 基本信息
      this.basicData.custCn.value = data.custCn;
      this.basicData.address.value = (data.province ? data.province : '') + (data.city ? data.city : '') + (data.area ? data.area : '') + data.addrStreet;
      this.basicData.scale.value = findValueByKey(this.dictList.scale, data.scale);
      this.basicData.generalTaxpayerFlag.value = data.generalTaxpayerFlag !== null ? data.generalTaxpayerFlag === 1 ? '是' : '否' : '';
      if (data.licenseAfileUrl) this.basicData.license.image.push(data.licenseAfileUrl);
      if (data.licenseBfileUrl) this.basicData.license.image.push(data.licenseBfileUrl);
      // 工商信息
      for (let i in this.icData) {
        this.icData[i].value = data[i];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cust-box{
  width:100%;
  overflow: hidden;
  text-align: left;
  background-color: $color-FFF;
  .cust-content{
    margin: 22px 14px 0 14px;
    border-bottom:1px solid $color-E6 ;
    .cust-title{
      margin-bottom: 24px;
      color:$color-333;
      font-weight: 600;
      font-size:$font-14;
    }
  }
  .cust-content:nth-child(2){
    margin-bottom: 0;
    border-bottom:0;
  }
}

</style>
