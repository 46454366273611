<template>
  <div class="cust-box">
    <div class="cust-content">
      <view-match :list="basicList" />
    </div>
  </div>
</template>

<script>
import viewMatch from '_c/view-match';
import { toArrays } from '@/lib/until.js';

export default {
  components: {
    viewMatch
  },
  data() {
    return {
      followInfo: {
        trackDate: {
          title: '跟进日期',
          value: ''
        },
        registerCreatetime: {
          title: '关联签到',
          value: ''
        },
        visitPurpose: {
          title: '跟进目的',
          value: ''
        },
        nextTrackTime: {
          title: '下次跟进日期',
          value: ''
        },
        trackRemark: {
          title: '跟进内容描述',
          value: ''
        },
        postName: {
          title: '需求岗位',
          value: ''
        },
        custRequire: {
          title: '客户需求',
          value: ''
        },
        preCooperationNum: {
          title: '预计合作人数',
          value: ''
        },
        preCooperationNumSocial: {
          title: '预计社保合作人数',
          value: ''
        },
        preCooperationNumSalary: {
          title: '预计薪酬合作人数',
          value: ''
        },
        preContTime: {
          title: '预计签约时间',
          value: ''
        },
        expectedActivationTime: {
          title: '预计激活时间',
          value: ''
        },
        preActivateNum: {
          title: '预计激活人数',
          value: ''
        },
        preProfit: {
          title: '预计营收',
          value: ''
        },
        preGrossProfit: {
          title: '预计毛利',
          value: ''
        },
        hasSupplier: {
          title: '是否有供应商',
          value: ''
        },
        supplierName: {
          title: '供应商名称',
          value: ''
        },
        isAccompany: {
          title: '是否有陪访人员',
          value: ''
        },
        accompanys: {
          title: '陪访人员',
          arrValue: []
        },
        fileDetails: {
          type: 'image',
          title: '附件',
          image: []
        },
        effective: {
          title: '是否有效',
          value: ''
        },
        signInRemark: {
          title: '备注',
          value: ''
        }
      }
    };
  },
  computed: {
    basicList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.followInfo);
    },
    icList() { // 对象是引用类型，传递的是引用地址，所以两个数组引用的是同一个对象，只要其中一个数组改变，就会导致对象改变，进而另一个引用的数组也会改。
      return toArrays(this.icData);
    }
  },
  methods: {
    getData(data) {
      this.busTypeId = data.busTypeId;
      for (let i in this.followInfo) {
        if (i !== 'accompanys' && i !== 'fileDetails') {
          this.followInfo[i].value = data[i];
        }
      }
      this.followInfo.preCooperationNum.value = data.preCooperationNum ? data.preCooperationNum + '人' : '';
      this.followInfo.preCooperationNumSocial.value = data.preCooperationNumSocial || data.preCooperationNumSocial === 0 ? data.preCooperationNumSocial + '人' : '';
      this.followInfo.preCooperationNumSalary.value = data.preCooperationNumSalary || data.preCooperationNumSalary === 0 ? data.preCooperationNumSalary + '人' : '';
      this.followInfo.preActivateNum.value = data.preActivateNum ? data.preActivateNum + '人' : '';
      this.followInfo.preProfit.value = data.preProfit ? data.preProfit + '元' : '';
      this.followInfo.preGrossProfit.value = data.preGrossProfit ? data.preGrossProfit + '元' : '';
      this.followInfo.accompanys.arrValue = data.accompanys !== null && data.accompanys.map(item => `${item.realName}(${item.staffCode})`);
      this.followInfo.isAccompany.value = data.isAccompany !== null ? data.isAccompany === 1 ? '是' : '否' : '';
      this.followInfo.fileDetails.image = data.fileDetails.map(item => item.fileUrl);
      this.followInfo.hasSupplier.value = data.hasSupplier !== null ? data.hasSupplier === 1 ? '是' : '否' : '';
      this.followInfo.effective.value = data.effective === 1 ? '是' : '否';
      if (this.busTypeId === '2') {
        this.basicList.splice(7, 1);
      } else {
        this.basicList.splice(8, 2);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cust-box{
  width:100%;
  overflow: hidden;
  text-align: left;
  background-color: $color-FFF;
  .cust-content{
    margin: 22px 14px 0 14px;
    .cust-title{
      margin-bottom: 24px;
      color:$color-333;
      font-weight: 600;
      font-size:$font-14;
    }
  }
}

</style>
